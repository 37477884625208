import request from "@/utils/request";

// 专题列表不分页
export function specialList(data) {
  return request({
    url: "/YmnTopic/qdYmnTopicList",
    method: "post",
    data,
  });
}

// 专题列表分页
export function specialListPage(data) {
  return request({
    url: "/YmnTopic/qdYmnTopicListPaging",
    method: "post",
    data,
  });
}

// 专题详情
export function specialInfo(params) {
  return request({
    url: "/YmnTopic/qdYmnTopicMap",
    method: "get",
    params,
  });
}

// 专题课程列表
export function specialLive(params) {
  return request({
    url: "/qd/HomePage/qdYmnTopicMapList",
    method: "get",
    params,
  });
}

// 一键预约
export function subscribeAll(data) {
  return request({
    url: "qd/Live/topicSubscribe",
    method: "post",
    data,
  });
}

// 专题渠道链接点击量
export function specialPv(data) {
  return request({
    url: "qd/Live/topicUpPv",
    method: "post",
    data,
  });
}
