<template>
  <div class="page-view">
    <review-mob v-if="isMobile"></review-mob>
    <review-pc v-else></review-pc>
  </div>
</template>

<script>
import ReviewPc from "./review-pc.vue";
import ReviewMob from "./review-mob.vue";
export default {
  components: {
    ReviewPc,
    ReviewMob,
  },
  metaInfo: {
    title: "因美纳讲堂 | 回顾",
  },
  data() {
    return {
      isMobile: this.$isMobile(),
    };
  },
};
</script>
