<template>
  <div class="review-mob">
    <mob-header></mob-header>
    <div class="wrap">
      <yl-swiper class="one" :dataList="slideDataList"></yl-swiper>
    </div>

    <!-- <yl-swiper
      class="two"
      :stack="true"
      :pagination="false"
      :dataList="videoSlideList"
    ></yl-swiper> -->
    <div class="special">
      <div class="title">
        <h4>专题课程</h4>
        <p @click="toMoreSpecial()">
          <span>更多</span>
          <i><img src="../../assets/right.png" alt="" /></i>
        </p>
      </div>
      <div class="special-box">
        <div
          v-for="(item, i) in specialList.slice(0, 4)"
          class="special-item"
          :key="i"
          @click="toSpecial(item.id)"
        >
          <img :src="item.thematicCover" alt="" />
          <!-- <p class="special-title">{{ item.topicName }}</p> -->
        </div>
      </div>
    </div>
    <div class="wrap live-wrap">
      <div class="title">
        <h4>回顾视频</h4>
        <p @click="toMore()">
          <span>更多</span>
          <i><img src="../../assets/right.png" alt="" /></i>
        </p>
      </div>
      <mob-video
        v-for="(item, i) in newReviewDataList"
        :key="i"
        :data="item"
        @refDataList="getNewReviewList()"
      ></mob-video>
    </div>
    <mob-footer-bar :active="1"></mob-footer-bar>
  </div>
</template>

<script>
import MobHeader from "../../components/mob-header.vue";
import YlSwiper from "../../components/yl-swiper.vue";
import MobFooterBar from "../../components/mob-footer-bar.vue";
import MobVideo from "../../components/mob-video.vue";
import { slideList } from "../../api/home";
import { videoSlide, newReviewList } from "../../api/review";
import wechat from "../../utils/wxShare";
import { wechatConfig } from "../../api/user";
import { specialList } from "../../api/special";
export default {
  components: {
    MobHeader,
    YlSwiper,
    MobFooterBar,
    MobVideo,
  },
  data() {
    return {
      slideDataList: [],
      videoSlideList: [],
      newReviewDataList: [],
      specialList: [],
    };
  },
  created() {
    this.getSlideList();
    this.getVideoSlide();
    this.getNewReviewList();
    this.getWechatConfig();
    this.getSpecialList();
  },
  methods: {
    getWechatConfig() {
      wechatConfig({
        url: location.href,
      }).then((res) => {
        if (res.code == 0) {
          this.wxConfigData = res.data;
          wechat.share(
            this.wxConfigData,
            "因美纳在线讲堂",
            "因美纳在线讲堂",
            this.$store.state.url,
            location.href
          );
        }
      });
    },
    // 轮播图数据
    getSlideList() {
      slideList().then((res) => {
        if (res.code == 0) {
          this.slideDataList = res.data;
        }
      });
    },
    // top5
    getVideoSlide() {
      videoSlide().then((res) => {
        if (res.code == 0) {
          this.videoSlideList = res.data;
        }
      });
    },
    // 最新点播列表
    getNewReviewList() {
      newReviewList().then((res) => {
        if (res.code == 0) {
          this.newReviewDataList = res.data;
        }
      });
    },
    toMore() {
      this.$router.push({ path: "/review/more" });
    },
    toMoreSpecial() {
      this.$router.push({ path: "/special" });
    },
    // 专题列表
    getSpecialList() {
      specialList().then((res) => {
        this.specialList = res.data;
      });
    },
    toSpecial(id) {
      this.$router.push({ path: "/special/" + id });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.review-mob {
  height: 100%;
  background: url("../../assets/mobbgsmall.png") 0 0 no-repeat;
  background-size: 100% auto;
  .special {
    .special-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .special-item {
        flex-shrink: 0;
        position: relative;
        margin-bottom: 0.1rem;
        width: 1.72rem;
        height: 0.77rem;
        border-radius: 0.06rem;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
        .special-title {
          position: absolute;
          left: 0.1rem;
          bottom: 0.1rem;
          font-size: 0.16rem;
          color: #fff;
        }
      }
    }
  }
  .wrap,
  .special {
    padding: 0 0.1rem;
    .yl-swiper.one {
      height: 1.68rem;
      border-radius: 0.08rem;
      border: none;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 0.35rem;
      margin-top: 0.1rem;
      h4 {
        margin: 0;
        font-size: 0.16rem;
      }
      p {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 0.14rem;
        i {
          display: inline-block;
          width: 0.1rem;
          height: 0.1rem;
          margin-left: 0.1rem;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .live-wrap {
    padding-bottom: 0.9rem;
  }
  .yl-swiper.two {
    height: 1.57rem;
    border: none;
    margin-top: 0.25rem;
  }
}
</style>
