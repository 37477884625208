<template>
  <div class="review-pc">
    <pc-header></pc-header>
    <div class="main">
      <yl-swiper class="one" :dataList="slideDataList"></yl-swiper>
      <!-- <yl-swiper
        class="two"
        :stack="true"
        :pagination="false"
        :dataList="videoSlideList"
      ></yl-swiper> -->
      <div class="special">
        <div class="title">
          <h4>专题课程</h4>
          <p @click="toMoreSpecial()">
            <span>更多</span>
            <i><img src="../../assets/right.png" alt="" /></i>
          </p>
        </div>
        <div class="special-box">
          <div
            v-for="(item, i) in specialList.slice(0, 4)"
            class="special-item"
            :key="i"
            @click="toSpecial(item.id)"
          >
            <img :src="item.thematicCover" alt="" />
            <!-- <p class="title">{{ item.topicName }}</p> -->
          </div>
        </div>
      </div>
      <div class="live-wrap">
        <div class="title">
          <h4>回顾视频</h4>
          <p @click="toMore()">
            <span>更多</span>
            <i><img src="../../assets/right.png" alt="" /></i>
          </p>
        </div>
        <div class="box">
          <pc-video
            v-for="(item, i) in newReviewDataList"
            :key="i"
            :data="item"
            @refDataList="getNewReviewList()"
          ></pc-video>
        </div>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from "../../components/pc-header.vue";
import YlSwiper from "../../components/yl-swiper.vue";
import PcFooter from "../../components/pc-footer.vue";
import PcVideo from "../../components/pc-video.vue";
import { slideList } from "../../api/home";
import { videoSlide, newReviewList } from "../../api/review";
import { specialList } from "../../api/special";
export default {
  components: {
    PcHeader,
    YlSwiper,
    PcFooter,
    PcVideo,
  },
  data() {
    return {
      slideDataList: [],
      videoSlideList: [],
      newReviewDataList: [],
      specialList: [],
    };
  },
  created() {
    this.getSlideList();
    this.getVideoSlide();
    this.getNewReviewList();
    this.getSpecialList();
  },
  methods: {
    // 轮播图数据
    getSlideList() {
      slideList().then((res) => {
        if (res.code == 0) {
          this.slideDataList = res.data;
        }
      });
    },
    // top5
    getVideoSlide() {
      videoSlide().then((res) => {
        if (res.code == 0) {
          this.videoSlideList = res.data;
        }
      });
    },
    // 最新点播列表
    getNewReviewList() {
      newReviewList().then((res) => {
        if (res.code == 0) {
          this.newReviewDataList = res.data;
        }
      });
    },
    toMore() {
      this.$router.push({ path: "/review/more" });
    },
    toMoreSpecial() {
      this.$router.push({ path: "/special" });
    },
    // 专题列表
    getSpecialList() {
      specialList().then((res) => {
        this.specialList = res.data;
      });
    },
    toSpecial(id) {
      this.$router.push({ path: "/special/" + id });
    },
  },
};
</script>

<style lang="scss" scoped>
p,
h4 {
  margin: 0;
}
.review-pc {
  height: 100%;
  background: url("../../assets/pcbg.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    width: 1200px;
    margin: 0 auto;
    .yl-swiper.one {
      height: 406px;
      border-radius: 8px;
      margin-top: 36px;
    }
    .yl-swiper.two {
      margin-top: 55px;
      height: 335px;
    }
    .special {
      .special-box {
        display: flex;
        justify-content: flex-start;
        cursor: pointer;
        .special-item {
          position: relative;
          width: 290px;
          height: 130px;
          border-radius: 8px;
          overflow: hidden;
          margin-right: 13px;
          &:last-child {
            margin-right: 0;
          }
          img {
            width: 100%;
            height: 100%;
          }
          .title {
            position: absolute;
            left: 15px;
            bottom: 15px;
            color: #fff;
            height: 20px;
            line-height: 20px;
            font-weight: bold;
            font-size: 18px;
          }
        }
      }
    }
    .live-wrap,
    .special {
      .title {
        display: flex;
        justify-content: space-between;
        height: 75px;
        line-height: 75px;
        padding-top: 10px;
        h4 {
          font-size: 24px;
        }
        p {
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: 16px;
          i {
            margin-left: 10px;
            width: 20px;
            height: 20px;
            img {
              width: 100%;
              display: block;
            }
          }
        }
      }
      .box {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 65px;
        min-height: 290px;
        .pc-video {
          margin-bottom: 35px;
        }
        .pc-video:nth-child(3n-1) {
          margin: 0 15px 35px;
        }
      }
    }
  }
}
</style>
