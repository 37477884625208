import request from "@/utils/request";

// top5
export function videoSlide(params) {
  return request({
    url: "/qd/LookBack/lookBackList",
    method: "get",
    params,
  });
}

// 最新点播列表
export function newReviewList(params) {
  return request({
    url: "/qd/LookBack/lookBackListPage",
    method: "get",
    params,
  });
}

// 更多
export function moreReviewList(params) {
  return request({
    url: "/qd/LookBack/lookBackListAll",
    method: "get",
    params,
  });
}
